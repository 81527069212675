import {Component} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {PageService} from '../../services/page.service';
import {Page} from '../../models';
import {Title} from '@angular/platform-browser';


@Component({
  selector: 'app-content-page',
  templateUrl: './content-page.component.html',
  styleUrls: ['./content-page.component.css']
})
export class ContentPageComponent {
  public language = 'en';
  public page: Page;
  public multiColumn = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private pageService: PageService,
    private titleService: Title,
  ) {
    // @ts-ignore
    router.events.subscribe( (event: Event) => {
      if (event instanceof NavigationEnd) {
        this.loadPage();
      }
    });
  }

  public loadPage() {
    this.route.url.subscribe(url => {
      this.language = url[0].path;
      const pageID = url[1].path;
      this.pageService.get(pageID, {lang: this.language}).subscribe(page => {
        this.page = page;
        this.titleService.setTitle(this.page.name);
        if (this.page.columns.length > 1) {
          this.multiColumn = true;
        }
      });
    });
  }
}
