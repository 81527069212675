// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
  margin:0 auto;
}
.content h3 {
  font-size:1.5em;
}
.content {
}

.cta {
  display:flex;
}
.cta .left, .cta .right {
  vertical-align:top;
  padding:2em 5em;
  color:white;
}

.cta h2, .cta h3, .cta h4, .cta h5 {
  color:#ffcc00;
}
.cta a {
  color:#bbbbff;
}
.cta a:visited {
  color:#ffbbff;
}
.cta .description {
  margin-bottom:2em;
}
.left {
  background-color:rgba(0,0,0,0.9);
  }
.right {
  background-color:rgba(0,0,0,0.95);
}

@media only screen and (min-width:50em) {
  .cta .left,.cta .right {
    width:50%;
  }
}

section {
  padding:1em 0;
  vertical-align:top;
}
p img {
  max-width:100%;
  height:auto;
}

.main {
  font-size:1.3rem;
}
iframe {
  max-width:100%;
  height:auto;
}

.flexy {
  display:flex;
  justify-content: space-around;
  gap:1rem;
}

.main {
  flex:2;
  order:2;
}
.sidebar {
  flex:1;
}

article.content {
  display:flex;
  align-items:center;
  width:80%;
  min-height:40rem;
}
.news {
  padding:1rem 2rem;
  display:flex;
}

.news > div {
  width:50%;
}
.feed article {
  padding:1rem 0 1.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/app/pages/home/home.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,eAAe;AACjB;AACA;AACA;;AAEA;EACE,YAAY;AACd;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,WAAW;AACb;;AAEA;EACE,aAAa;AACf;AACA;EACE,aAAa;AACf;AACA;EACE,aAAa;AACf;AACA;EACE,iBAAiB;AACnB;AACA;EACE,gCAAgC;EAChC;AACF;EACE,iCAAiC;AACnC;;AAEA;EACE;IACE,SAAS;EACX;AACF;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;AACA;EACE,cAAc;EACd,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,cAAc;EACd,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,6BAA6B;EAC7B,QAAQ;AACV;;AAEA;EACE,MAAM;EACN,OAAO;AACT;AACA;EACE,MAAM;AACR;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,SAAS;EACT,gBAAgB;AAClB;AACA;EACE,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,SAAS;AACX;AACA;EACE,qBAAqB;AACvB","sourcesContent":[".content {\n  margin:0 auto;\n}\n.content h3 {\n  font-size:1.5em;\n}\n.content {\n}\n\n.cta {\n  display:flex;\n}\n.cta .left, .cta .right {\n  vertical-align:top;\n  padding:2em 5em;\n  color:white;\n}\n\n.cta h2, .cta h3, .cta h4, .cta h5 {\n  color:#ffcc00;\n}\n.cta a {\n  color:#bbbbff;\n}\n.cta a:visited {\n  color:#ffbbff;\n}\n.cta .description {\n  margin-bottom:2em;\n}\n.left {\n  background-color:rgba(0,0,0,0.9);\n  }\n.right {\n  background-color:rgba(0,0,0,0.95);\n}\n\n@media only screen and (min-width:50em) {\n  .cta .left,.cta .right {\n    width:50%;\n  }\n}\n\nsection {\n  padding:1em 0;\n  vertical-align:top;\n}\np img {\n  max-width:100%;\n  height:auto;\n}\n\n.main {\n  font-size:1.3rem;\n}\niframe {\n  max-width:100%;\n  height:auto;\n}\n\n.flexy {\n  display:flex;\n  justify-content: space-around;\n  gap:1rem;\n}\n\n.main {\n  flex:2;\n  order:2;\n}\n.sidebar {\n  flex:1;\n}\n\narticle.content {\n  display:flex;\n  align-items:center;\n  width:80%;\n  min-height:40rem;\n}\n.news {\n  padding:1rem 2rem;\n  display:flex;\n}\n\n.news > div {\n  width:50%;\n}\n.feed article {\n  padding:1rem 0 1.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
