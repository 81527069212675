import {Component} from '@angular/core';
import {Category, Product, Subcategory, SiteInfo} from '../../models';
import {ProductService} from '../../services/product.service';
import {SiteInfoService} from '../../services/site-info.service';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {CategoryService} from '../../services/category.service';
import {SubcategoryService} from '../../services/subcategory.service';
import {forkJoin} from 'rxjs';
import {Title} from '@angular/platform-browser';


@Component({
  selector: 'app-catalogue',
  templateUrl: './catalogue.component.html',
  styleUrls: ['./catalogue.component.css']
})
export class CatalogueComponent {
  public products: Product[] = Array();
  public categories: Category[];
  public selectedCategory: Category;
  public siteInfo: SiteInfo;
  public productStyle : {};
  public productLinkColor : string;

  public subcategories: Subcategory[];
  public selectedSubcategories: Subcategory[] = Array();
  public language = 'en';

  public loaded = false;
  public orderOptions: string[] = ['popularity', 'alphabetical', 'newest'];
  public order = 'popularity';

  constructor(
    private productService: ProductService,
    private categoryService: CategoryService,
    private subcategoryService: SubcategoryService,
    private siteInfoService: SiteInfoService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
  ) {
    // @ts-ignore
    router.events.subscribe( (event: Event) => {
      if (event instanceof NavigationStart) {
        this.loaded = false;
      }
      if (event instanceof NavigationEnd) {
        this.loadPage();
      }
    });
  }

  public getProducts(category: Category, selectedSubcategories: Subcategory[]) {
    let selectedSubs = selectedSubcategories.map(sub => sub.id).join(',');
    if (selectedSubs === '') {
      selectedSubs = '0';
    }
    const parameters = {lang: this.language, category: category.id, subcategory: selectedSubs, listed: true, order: this.order};
    this.productService.list(parameters).subscribe(products => {
      this.products = products;
      this.loaded = true;
    });
  }

  public isSelected(subcategory: Subcategory, subcategories: Subcategory[]): boolean {
    return subcategories.filter(sub => sub.url === subcategory.url).length > 0;
  }

  public changeSort() {
    this.getProducts(this.selectedCategory, this.selectedSubcategories);
  }

  public toggleSubcategory(subcategory: Subcategory) {
    if (this.isSelected(subcategory, this.selectedSubcategories)) {
      this.selectedSubcategories = this.selectedSubcategories.filter(sub => sub.url !== subcategory.url);
    } else {
      this.selectedSubcategories.push(subcategory);
    }
    this.getProducts(this.selectedCategory, this.selectedSubcategories);
  }

  public selectTab(category) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {category: category.slug},
      queryParamsHandling: 'merge'
    });
  }

  public selectCategory(category: Category) {
    this.subcategoryService.list({lang: this.language, category: category.id}).subscribe(subcategories => {
      this.subcategories = subcategories;
      this.selectedSubcategories = subcategories;
      this.getProducts(category, subcategories);
      this.selectedCategory = category;
    });
  }

  public loadPage() {
    this.route.queryParams.subscribe(params => {
      this.route.url.subscribe(url => {
        this.language = url[0].path;
        forkJoin(
          this.categoryService.list({lang: this.language}),
          this.siteInfoService.list({lang: this.language})
        ).subscribe(([categories, siteInfo]) => {
          this.titleService.setTitle('Products');
          this.categories = categories;
          this.siteInfo = siteInfo[0];
          this.productStyle = {
            'background' : this.siteInfo.product_menu_background,
            'color': this.siteInfo.product_menu_text,
            'border': '1px solid #00000055',
          };
          this.productLinkColor = this.siteInfo.product_menu_text;
          let catTemp: Category;
          if (params.hasOwnProperty('category')) {
            catTemp = this.categories.filter(category => category.slug === (params['category']))[0];
          } else {
            catTemp = this.categories[0];
          }
          this.selectCategory(catTemp);
        });
      });
    });
  }
}
