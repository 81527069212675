// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.products {
  padding:2em;
}

.categories {
  padding:0 2em;
}

.categories a {
  display:inline-block;
  height:100%;
  cursor:pointer;
  padding:1em 2em;
  border-right:2px solid #ffffff55;
  text-decoration:none;
}

.categories a:last-child {
  border-right:0;
}
.categories a:hover {
  text-decoration:underline;
}
.subcategories {
  display:flex;
  align-items:center;
}
mat-checkbox {margin-right:2em;}
.sub-action-bar {
  padding:0.25em 2em;
  display:flex;
  justify-content: space-between;
  align-content:center;
}
.mat-radio-button {
  padding:0 0.75rem;
}
mat-spinner {
  display:inline-block;
  width:4em;
  position:relative;
  left:4em;
  top:2em;
}
.product {
  display:inline-block;
  width:18em;
  margin:0 1em 2em 0;
  float:left;
}
.product:nth-child(4n) {
  margin-right:0;
}
.product:nth-child(4n+1) {
  clear:left;
}
.products:after {
  content:'';
  display:block;
  clear:left;
}
.lit {
  color:#fff !important;
}

.dar {
  color:#000 !important;
 }
`, "",{"version":3,"sources":["webpack://./src/app/pages/catalogue/catalogue.component.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,oBAAoB;EACpB,WAAW;EACX,cAAc;EACd,eAAe;EACf,gCAAgC;EAChC,oBAAoB;AACtB;;AAEA;EACE,cAAc;AAChB;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,YAAY;EACZ,kBAAkB;AACpB;AACA,cAAc,gBAAgB,CAAC;AAC/B;EACE,kBAAkB;EAClB,YAAY;EACZ,8BAA8B;EAC9B,oBAAoB;AACtB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,oBAAoB;EACpB,SAAS;EACT,iBAAiB;EACjB,QAAQ;EACR,OAAO;AACT;AACA;EACE,oBAAoB;EACpB,UAAU;EACV,kBAAkB;EAClB,UAAU;AACZ;AACA;EACE,cAAc;AAChB;AACA;EACE,UAAU;AACZ;AACA;EACE,UAAU;EACV,aAAa;EACb,UAAU;AACZ;AACA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;CACtB","sourcesContent":[".products {\n  padding:2em;\n}\n\n.categories {\n  padding:0 2em;\n}\n\n.categories a {\n  display:inline-block;\n  height:100%;\n  cursor:pointer;\n  padding:1em 2em;\n  border-right:2px solid #ffffff55;\n  text-decoration:none;\n}\n\n.categories a:last-child {\n  border-right:0;\n}\n.categories a:hover {\n  text-decoration:underline;\n}\n.subcategories {\n  display:flex;\n  align-items:center;\n}\nmat-checkbox {margin-right:2em;}\n.sub-action-bar {\n  padding:0.25em 2em;\n  display:flex;\n  justify-content: space-between;\n  align-content:center;\n}\n.mat-radio-button {\n  padding:0 0.75rem;\n}\nmat-spinner {\n  display:inline-block;\n  width:4em;\n  position:relative;\n  left:4em;\n  top:2em;\n}\n.product {\n  display:inline-block;\n  width:18em;\n  margin:0 1em 2em 0;\n  float:left;\n}\n.product:nth-child(4n) {\n  margin-right:0;\n}\n.product:nth-child(4n+1) {\n  clear:left;\n}\n.products:after {\n  content:'';\n  display:block;\n  clear:left;\n}\n.lit {\n  color:#fff !important;\n}\n\n.dar {\n  color:#000 !important;\n }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
