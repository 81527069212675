import {Component} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {ProductService} from '../../services/product.service';
import {SiteInfoService} from '../../services/site-info.service';
import {Category, Comparison, Product, SiteInfo} from '../../models';
import {ComparisonService} from '../../services/comparison.service';
import {forkJoin} from 'rxjs';
import {CategoryService} from '../../services/category.service';
import {Title} from '@angular/platform-browser';


@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})

export class ProductComponent {
  public language = 'en';
  public product: Product;
  public categories: Category[] = Array();
  public comparableProducts: Product[] = Array();
  public comparison: Comparison;
  public siteInfo: SiteInfo;
  public displayedColumns: string[] = ['name', 'product', 'comp_one'];
  public productStyle : {};
  public productLinkStyle : {};
  public sidebarStyle : {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private productService: ProductService,
    private siteInfoService: SiteInfoService,
    private categoryService: CategoryService,
    private comparisonService: ComparisonService,
    private titleService: Title,
  ) {
    // @ts-ignore
    router.events.subscribe( (event: Event) => {
      if (event instanceof NavigationEnd) {
        this.loadPage();
      }
    });
  }

  public loadPage() {
    this.route.url.subscribe(url => {
      this.language = url[0].path;
      const prodID = url[2].path;
      const productS = this.productService.get(prodID, {lang: this.language});
      const categoryS = this.categoryService.list({lang: this.language})
      const siteS = this.siteInfoService.list({lang: this.language})

      forkJoin([productS, categoryS, siteS]).subscribe(results => {
        this.categories = results[1];
        this.product = results[0];
        this.siteInfo = results[2][0];
        this.titleService.setTitle(this.product.name);
        this.productStyle = {
          'background' : this.siteInfo.product_menu_background,
          'color': this.siteInfo.product_menu_text
        };
        this.sidebarStyle = {
          'background' : this.siteInfo.product_menu_background,
          'color': this.siteInfo.product_menu_text
        }
        this.productLinkStyle = {
          'color': this.siteInfo.product_menu_text
        }
        if (this.product.has_comparison) {
          this.comparisonService.get(prodID, {lang: this.language}).subscribe(comparison => {
            this.comparison = comparison;
            if (this.comparison.two_name) {
              this.displayedColumns = ['name', 'product', 'comp_one', 'comp_two'];
            }
          });
        }
      });
    });
  }
}
