import { Injectable } from '@angular/core';
import {ProductService} from './product.service';
import {JointService} from './joint.service';
import {PenetrationService} from './penetration.service';
import {PageService} from './page.service';
import {Observable, forkJoin, of} from 'rxjs';
import { v4 as uuid } from 'uuid';


@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(
    private productService: ProductService,
    private jointService: JointService,
    private penetrationService: PenetrationService,
    private pageService: PageService,
  ) { }

  public search(language: string, q: string, models: string[]): Observable<any> {
    const observables = [];
    const params = {lang: language,  search: q, session: uuid()};

    if (models.includes('product')) {
      observables.push(this.productService.list(params));
    }
    if (models.includes('page')) {
      observables.push(this.pageService.list(params));
    }
    if (models.includes('joint')) {
      observables.push(this.jointService.list(params));
    }
    if (models.includes('penetration')) {
      observables.push(this.penetrationService.list(params));
    }

    if (observables.length > 0) {
      return forkJoin(observables);
    } else {
      return of({});
    }
  }
}
