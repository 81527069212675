import {Injectable} from '@angular/core';
import {IoService} from './io.service';
import {Page} from '../models';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Pagination} from './pager.service';

@Injectable()
export class PageService extends IoService {
  protected model = Page;
  protected listUrl = '/pages/';
}
