import {Component, OnInit} from '@angular/core';
import {NewsItem, Page, SiteInfo} from '../../models';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {SiteInfoService} from '../../services/site-info.service';
import {NewsItemService} from '../../services/news-item.service';
import {Title} from '@angular/platform-browser';
import {forkJoin} from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  public page: Page;
  public siteInfo: SiteInfo;
  public loaded = false;
  public language = 'en';
  public newsItems: NewsItem[];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private siteInfoService: SiteInfoService,
    private newsItemService: NewsItemService,
    private titleService: Title,
  ) {
    // @ts-ignore
    router.events.subscribe( (event: Event) => {
      if (event instanceof NavigationEnd) {
        this.loadPage();
      }
    });
  }


  public loadPage() {
    this.route.url.subscribe(url => {
      this.language = url[0].path;
    });

    const siteS = this.siteInfoService.list({lang: this.language});
    const newsS = this.newsItemService.list({lang: this.language});

    forkJoin([siteS, newsS]).subscribe(results => {
      const sites = results[0];
      const news = results[1];
      this.siteInfo = sites[0];
      this.titleService.setTitle(this.siteInfo.name);
      this.newsItems = news;

    })
  }

  ngOnInit() {
    this.loadPage();
  }

}
